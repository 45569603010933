import React from "react";

import {useParams, Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Buffer } from 'buffer';
import ResultAnnotator from "./ResultAnnotator"

export default function ResultDocument(props) {

  const { documentID } = useParams()

  const [data, setData] = React.useState(null);
  const [state, setState] = React.useState({ value: [], fetched: false});  
  const navigate = useNavigate();
  const [abbreviate, setAbbreviate] = React.useState(1);
  const [str_abbreviate, setStrAbbreviate] = React.useState("Abbreviate");
  const [braces, setBraces] = React.useState(1);
  const [str_braces, setStrBraces] = React.useState("View Braces");
  const [exp_map, setExpMap] = React.useState([]);
  const [agreed, setAgreed] = React.useState(-1);
  const [str_agreed, setStrAgreed] = React.useState("Hide Confirmed Expansions");
  const [show_agreed, setShowAgreed] = React.useState(false);

  const getDocument = () => {
    fetch("/api/v1/unabbreviate/assigned-document", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
      },
      body: JSON.stringify({
        "provider_id": props.user.email,
        "assigned_document_id": documentID
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          setData(data)
        })
      } else if(response.status == 300){
        window.localStorage.removeItem('oplus-user')
        window.location.reload(false);
        navigate("/");
      }      
    })
  } 

  const getExpandedAbbreviations = () => {
    fetch("/api/v1/unabbreviate/expanded-abbreviations-list", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
      },
      body: JSON.stringify({
        "provider_id": props.user.email,
        "assigned_document_id": documentID
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {        
          const values = [];
          if(data && 'expanded_abbreviations' in data){
            for (const expansion of data.expanded_abbreviations){ 
              values.push({
                start: expansion.start_pos,
                end: expansion.end_pos,
                tag: expansion.long_form,
                isNew: expansion.is_new,
                shortForm: expansion.short_form              
              });
            }
          }
          setState({value: values, fetched: true})
        })
      } else if(response.status == 300){
        window.localStorage.removeItem('oplus-user')
        window.location.reload(false);
        navigate("/");
      }       
    })
  }

  const getExpMap = () => {
    fetch("/api/v1/unabbreviate/get-previous-agreed-expansions", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
      },
      body: JSON.stringify({
        "document_id": documentID,
        "provider_id": props.user.email
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          setExpMap(data);
          if (state.fetched == true) {
            data.map(
                (item) => {
                  let present = false;
                  for (let i of state.value) {
                    if (i.start == item.suggestion_start && i.end == item.suggestion_end) {
                      present = true
                    }
                  }
                  if (present == false) {
                    setShowAgreed(true);
                  }
                }
            )
          }
        })
      } else if(response.status == 300){
        window.localStorage.removeItem('oplus-user')
        window.location.reload(false);
        navigate("/");
      }
    })
  }

  React.useEffect(() => {
    getDocument();
    getExpandedAbbreviations();
  }, []);

  React.useEffect(() => {
    getExpMap();
  }, [state]);

  const getAbbreviate = () => {
    {
      abbreviate == -1 ?
          setStrAbbreviate("UnAbbreviate")
          :
          setStrAbbreviate("Abbreviate")
    }
  }

  React.useEffect(() => {
    getAbbreviate();
  }, [abbreviate]);

  const getBraces = () => {
    {
      braces == -1 ?
          setStrBraces("Hide Braces")
          :
          setStrBraces("View Braces")
    }
  }

  React.useEffect(() => {
    getBraces();
  }, [braces]);

  const getAgreed = () => {
    {
      agreed == 1 ?
          setStrAgreed("View Confirmed Expansions")
          :
          setStrAgreed("Hide Confirmed Expansions")
    }
  }

  React.useEffect(() => {
    getAgreed();
  }, [agreed]);


  return (    
    <>    
      {data != null ? (
        <>
        <Row>
          <Col>
            <strong>Admission ID:</strong> {data['admission_id']}
          </Col>
          <Col>
            <strong>Diagnoses:</strong> {data['diagnoses']}
          </Col>
          <Col>
            <strong>Category:</strong> {data['category']}
          </Col>
          <Col>
            <strong>Description:</strong> {data['description']}
          </Col>
        </Row>
        <Row>          
          <Col>
          <hr className="py-1"/>
            <ResultAnnotator
                      content={Buffer.from(data['content'], 'base64').toString('utf8')}
                      value={state.value}
                      user={props.user}
                      abbreviate={abbreviate*-1}
                      braces={braces*-1}
                      exp={exp_map}
                      agreed={agreed*-1}
                      getSpan={span => ({
                          ...span
                      })}                
            /> 
          <hr className="py-1"/>
          </Col>          
        </Row>
        <Row>          
          <Col className="text-start">
            <Button variant="secondary" size="md" onClick={() => {
              navigate("/");
            }}>Home</Button>
            {/*removed confirmed expansions button*/}
          </Col>
          <Col className="text-end">
            <Button variant="dark" size="md" onClick={() => {
              setAbbreviate(abbreviate * -1);
            }}>
              {str_abbreviate}
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="primary" size="md" onClick={() => {
              setBraces(braces * -1);
            }}>
              {str_braces}
            </Button>
          </Col>
        </Row>
        </>
      ) : (
        null
      )}
    </>
  );
}