import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <nav aria-label="assigned documents">
      <ul className="pagination justify-content-center">
        {/* Left navigation arrow */}
        <li className={classnames('page-item', {disabled: currentPage === 1})}>
          <a className="page-link" href="#" onClick={onPrevious} aria-disabled={currentPage === 1} aria-label="Previous"><span aria-hidden="true">&laquo;</span></a>
        </li>
        {paginationRange.map(pageNumber => {
          
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return <li className="page-item disabled"><a className="page-link" aria-disabled="true" href="#">&#8230;</a></li>;
          }
      
          // Render our Page Pills
          return (
            <li className={classnames('page-item', {active: pageNumber === currentPage})} {...(pageNumber === currentPage ? {'aria-current': 'page'} : {})}>
              <a className="page-link" href="#" onClick={() => onPageChange(pageNumber)}>{pageNumber}</a>
            </li>
          );
        })}
        {/*  Right Navigation arrow */}
        <li className={classnames('page-item', {disabled: currentPage === lastPage})}>
          <a className="page-link" href="#" aria-disabled={currentPage === lastPage} onClick={onNext} aria-label="Next"><span aria-hidden="true">&raquo;</span></a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;