import React from 'react'
import sortBy from 'lodash.sortby'
import take from 'lodash.take'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'


const splitWithOffsets = (text, offsets, suggestion_offsets) => {
    let lastEnd = 0
    const splits = []
    for (let offset of sortBy(offsets, o => o.start)) {
      const {start, end} = offset
      if (lastEnd < start) {
          let suggestion_lastEnd = lastEnd
          let current_start = lastEnd
          let current_end = start
          for (let suggestion_offset of sortBy(suggestion_offsets, s => s.suggestion_start)) {
              const {suggestion_start, suggestion_end, suggestion_type, suggestion_lf} = suggestion_offset
              if (suggestion_start < current_start) {
                  continue;
              }
              if (suggestion_end > current_end){
                  break;
              }
              if (suggestion_lastEnd < suggestion_start) {
                splits.push({
                  start: suggestion_lastEnd,
                  end: suggestion_start,
                  content: text.slice(suggestion_lastEnd, suggestion_start),
                })
              }

              let content_now = text.slice(suggestion_start, suggestion_end);
              if (suggestion_type == "expand_in_place") {
                  content_now = suggestion_lf
              }

              splits.push({
                  start: suggestion_start,
                  end: suggestion_end,
                  suggestion_mark: true,
                  suggestion_type: suggestion_type,
                  suggestion_lf: suggestion_lf,
                  content: content_now,
              })
              suggestion_lastEnd = suggestion_end
            }
            if (suggestion_lastEnd < current_end) {
              splits.push({
                start: suggestion_lastEnd,
                end: current_end,
                content: text.slice(suggestion_lastEnd, current_end),
              })
            }

      }
      splits.push({
        ...offset,
        mark: true,
        content: text.slice(start, end),
      })
      lastEnd = end
    }
    if (lastEnd < text.length) {
          let suggestion_lastEnd = lastEnd
          let current_start = lastEnd
          let current_end = text.length
          for (let suggestion_offset of sortBy(suggestion_offsets, s => s.suggestion_start)) {
              const {suggestion_start, suggestion_end, suggestion_type, suggestion_lf} = suggestion_offset
              if (suggestion_start < current_start) {
                  continue;
              }
              if (suggestion_end > current_end){
                  break;
              }
              if (suggestion_lastEnd < suggestion_start) {
                splits.push({
                  start: suggestion_lastEnd,
                  end: suggestion_start,
                  content: text.slice(suggestion_lastEnd, suggestion_start),
                })
              }

              let content_now = text.slice(suggestion_start, suggestion_end);
              if (suggestion_type == "expand_in_place") {
                  content_now = suggestion_lf
              }

              splits.push({
                  start: suggestion_start,
                  end: suggestion_end,
                  suggestion_mark: true,
                  suggestion_type: suggestion_type,
                  suggestion_lf: suggestion_lf,
                  content: content_now,
              })
              suggestion_lastEnd = suggestion_end
            }
            if (suggestion_lastEnd < current_end) {
              splits.push({
                start: suggestion_lastEnd,
                end: current_end,
                content: text.slice(suggestion_lastEnd, current_end),
              })
            }
    }
    return splits
}

function Mark(props) {
    return (
    <>
        {props.braces == 1 ?
                props.abbreviate == 1 ?
                    <mark
                        style={{backgroundColor: '#FFA500', padding: '0 0px'}}
                        data-start={props.start}
                        data-end={props.end}
                    >
                        {props.content}
                        {props.tag && (
                            <span style={{fontSize: '0.7em', fontWeight: 500, marginLeft: 6}}>[{props.tag}]</span>
                        )
                        }
                    </mark>
                    :
                    <mark
                        style={{backgroundColor: '#90ee90', padding: '0 0px'}}
                        data-start={props.start}
                        data-end={props.end}
                    >
                        {props.tag}
                        {props.content && (
                            <span style={{fontSize: '0.7em', fontWeight: 500, marginLeft: 6}}>[{props.content}]</span>
                        )
                        }
                    </mark>
            :
                props.abbreviate == 1 ?
                    <mark
                        style={{backgroundColor: '#FFA500', padding: '0 0px'}}
                        data-start={props.start}
                        data-end={props.end}
                    >
                        {props.content}
                    </mark>
                    :
                    <mark
                        style={{backgroundColor: '#90ee90', padding: '0 0px'}}
                        data-start={props.start}
                        data-end={props.end}
                    >
                        {props.tag}
                    </mark>
        }
    </>)
}

const Split = props => {
  if (props.mark) return <Mark {...props} />
  else if (props.agreed && props.suggestion_mark && props.suggestion_type == "expand_in_place") {
      if (props.agreed == 1) {
          return (
              <span
                  style={{backgroundColor: '#FFFFFF'}}  // not highlighting, keep white
              >
                  {props.content}
                </span>
              )
      }
      return (
              <span
                  style={{backgroundColor: '#FFFFFF'}}
              >
                  {props.content}
                </span>
              )
  }

  return (
    <span
      data-start={props.start}
      data-end={props.end}
    >
      {props.content}
    </span>
  )
}

const ResultAnnotator = (props) => {
  const getSpan = (span) => {
    if (props.getSpan) return props.getSpan(span)
    return {start: span.start, end: span.end}
  }

  const {content, value, style, abbreviate, braces, exp, agreed} = props
  const splits = splitWithOffsets(content, value, exp)

  return (
    <div style={style}>
      {splits.map((split) => (
        <Split key={`${split.start}-${split.end}`} {...split} user={props.user} abbreviate={abbreviate} braces={braces} exp={exp} agreed={agreed}/>
      ))}      
    </div>
  )
}

export default ResultAnnotator