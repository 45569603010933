import React from "react";
import axios from 'axios';

import Button from 'react-bootstrap/Button'

export default function GetMoreDocuments(props) {

    const [requestStatus, setRequestStatus] = React.useState(-1);

    React.useEffect(() => {
        requestMoreDocuments();
      }, []);

    function requestMoreDocuments() {        
        if(requestStatus < 0){
            if(props.user.tags.includes("test_user")){
                setRequestStatus(3);
            } else {
                setRequestStatus(0); // In progress
                axios.post('/api/v1/unabbreviate/assign-new-documents', {
                        provider_id: props.user.email,
                        number_of_documents: 10
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'oplus-api-token': props.user.token
                        }
                    }).then(res => setRequestStatus(1)).catch(err => setRequestStatus(2));
            }
        }
    }

    return (
      <>    
        {{
            0: <div className='text-warning mb-5 fw-light fs-5'>Requesting new notes, please be patient...</div>,            
            1: <div className='text-primary mb-5 fw-light fs-5'>Thank you for your hard work! We've successfully assigned new notes to your account!</div>,
            2: <div className='text-warning mb-5 fw-light fs-5'>Sorry, I can't assign new notes to your account. Please make sure to submit all pending notes before requesting more.</div>,
            3: <div className='text-warning mb-5 fw-light fs-5'>New notes can't be assinged to your test account. Please contact customer support: <u>ayush@oplus.ai</u> for requesting more notes.</div>
        }[requestStatus]}
        <Button variant="secondary" href="/">
            Home
        </Button>
      </>
    );
}