import React from "react";

import {useParams, Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Buffer } from 'buffer';
import ConflictAnnotator from "./ConflictAnnotator"

export default function ConflictDocument(props) {

  const { documentID } = useParams()

  const [data, setData] = React.useState(null);
  const [conflicts, setConflicts] = React.useState({ value: [], fetched: false});
  const [state, setState] = React.useState({ value: [], fetched: false});
  const navigate = useNavigate();
  const [exp_map, setExpMap] = React.useState([]);

  const getDocument = () => {
    fetch("/api/v1/unabbreviate/assigned-document", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
      },
      body: JSON.stringify({
        "provider_id": props.user.email,
        "assigned_document_id": documentID
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          setData(data)
        })
      } else if(response.status == 300){
        window.localStorage.removeItem('oplus-user')
        window.location.reload(false);
        navigate("/");
      }      
    })
  }

  const getConflictedAbbreviations = () => {
    fetch("/api/v1/unabbreviate/get-conflicted-abbreviations-with-current-expansions", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
      },
      body: JSON.stringify({
        "provider_id": props.user.email,
        "document_id": documentID
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          const values = [];
          if (data) {
            for (const expansion of data) {
                  values.push({
                    start: expansion.start_pos,
                    end: expansion.end_pos,
                    tag: expansion.current_expansion,
                    shortForm: expansion.short_form,
                    selfLongForm: expansion.self_long_form,
                    conflictingLongForm: expansion.conflicting_long_form,
                    isNew: expansion.is_new
                });
            }
          }
          setConflicts({value: values, fetched: true});
        })
      } else if(response.status == 300){
        window.localStorage.removeItem('oplus-user')
        window.location.reload(true);
        navigate("/");
      }
    })
  }


  const updateExpandedAbbreviations = () => {
    if (conflicts.fetched) {
      const filteredValues = conflicts.value.filter(value => value.tag);
      const expandedAbbreviations = filteredValues.map(value => ({
        start_pos: value.start,
        end_pos: value.end,
        short_form: value.shortForm,
        long_form: value.tag,
        is_new: value.isNew
      }));
      axios.post('/api/v1/unabbreviate/update-expanded-abbreviations', {
        provider_id: props.user.email,
        assigned_document_id: documentID,
        expanded_abbreviations: expandedAbbreviations
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'oplus-api-token': props.user.token,
      }}).catch(function (error) {
        console.log(error);
      });
    }
  }

  const getExpMap = () => {
    fetch("/api/v1/unabbreviate/get-previous-agreed-expansions", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
      },
      body: JSON.stringify({
        "document_id": documentID,
        "provider_id": props.user.email
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          setExpMap(data);
        })
      } else if(response.status == 300){
        window.localStorage.removeItem('oplus-user')
        window.location.reload(false);
        navigate("/");
      }
    })
  }


  function submitDocument() {
    axios.post('/api/v1/unabbreviate/submit-document', {
      provider_id: props.user.email,
      assigned_document_id: documentID      
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
    }}).then(() => {
      navigate("/");
   }).catch(function (error) {
      console.log(error);
    });
  }

  function submitConflictsCount() {
      axios.post('/api/v1/unabbreviate/update-stats-count', {
        provider_id: props.user.email,
        num_expansions: 0,
        num_conflicts: conflicts.value.length,
        num_claimed_credits: 0,
        num_new_short_forms: 0,
        num_new_long_forms: 0
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'oplus-api-token': props.user.token,
      }}).catch(function (error) {
        console.log(error);
      });
  }

  async function submitStats(shortForm, stats) {    
    if(!props.user.tags.includes("test_user")){
      await axios.post('/api/v1/unabbreviate/report-stats', {
        provider_id: props.user.email,
        impressions: stats.impressions.map(longForm => ({short_form: shortForm, long_form: longForm})),
        clicks: stats.clicks.map(longForm => ({short_form: shortForm, long_form: longForm}))
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'oplus-api-token': props.user.token
      }}).catch(function (error) {
        console.log(error);
      });
    }
  }

  const setConflictedAbbreviations = (values) => {
    setConflicts({...conflicts, value: values})
  }

  React.useEffect(() => {
    getExpMap();
    getDocument();
    getConflictedAbbreviations();
  }, []);

  React.useEffect(() => {
    updateExpandedAbbreviations();
  }, [conflicts]);

  return (    
    <>    
      {data != null ? (
        <>
          <Row>
            {/*<hr className="py"/>*/}
            <Col>
              <hr className="py"/>
                <center><strong>Note</strong>: Conflicts were detected with another submission; please choose the extended form definitions from the choices provided.</center>
              <hr className="py"/>
            </Col>
          </Row>
          <br/>
          <br/>
        <Row>
          <Col>
            <strong>Admission ID:</strong> {data['admission_id']}
          </Col>
          <Col>
            <strong>Diagnoses:</strong> {data['diagnoses']}
          </Col>
          <Col>
            <strong>Category:</strong> {data['category']}
          </Col>
          <Col>
            <strong>Description:</strong> {data['description']}
          </Col>
        </Row>
        <Row>          
          <Col>
          <hr className="py-1"/>
            <ConflictAnnotator
              content={Buffer.from(data['content'], 'base64').toString('utf8')}
              value={conflicts.value}
              submitStats={submitStats}
              user={props.user}
              onChange={setConflictedAbbreviations}
              exp={exp_map}
              getSpan={span => ({
                  ...span
              })}
            /> 
          <hr className="py-1"/>
          </Col>          
        </Row>
        <Row>          
          <Col className="text-start">
            <Link onClick={updateExpandedAbbreviations} to="/">
              <Button variant="secondary" size="md">Home</Button>
            </Link>
            {' '}
            </Col>
            <Col className="text-end">
            <Button variant="primary" size="md" onClick={() => {
              updateExpandedAbbreviations();
              submitConflictsCount();
              submitDocument();
            }}>Submit</Button>            
          </Col>          
        </Row>
        </>
      ) : (
        null
      )}
    </>
  );
}