import React from "react";

import {useParams, Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import { Buffer } from 'buffer';
import TextAnnotator from "./TextAnnotator"


export default function Document(props) {

  const { documentID } = useParams()

  const [data, setData] = React.useState(null);
  const [state, setState] = React.useState({ value: [], fetched: false});
  const [abbr_map, setAbbrMap] = React.useState([]);
  const navigate = useNavigate();
  const [sugg, setSugg] = React.useState(-1);
  const [str_sugg, setStrSugg] = React.useState("Hide Suspected Abbreviations");
  const [agreed, setAgreed] = React.useState(-1);
  const [str_agreed, setStrAgreed] = React.useState("Hide Confirmed Expansions");
  const [show_agreed, setShowAgreed] = React.useState(false);


  const getDocument = () => {
    fetch("/api/v1/unabbreviate/assigned-document", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
      },
      body: JSON.stringify({
        "provider_id": props.user.email,
        "assigned_document_id": documentID
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          setData(data)
        })
      } else if(response.status == 300){
        window.localStorage.removeItem('oplus-user')
        window.location.reload(false);
        navigate("/");
      }
    })
  }

  const getAbbrMap = () => {
    fetch("/api/v1/unabbreviate/get-predicted-abbr-map-for-document", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
      },
      body: JSON.stringify({
        "document_id": documentID,
        "provider_id": props.user.email
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          setAbbrMap(data);
          data.map (
              (item) => {
                if (item.suggestion_type == "expand_in_place") {
                  setShowAgreed(true);
                }
              }
          )
        })
      } else if(response.status == 300){
        window.localStorage.removeItem('oplus-user')
        window.location.reload(false);
        navigate("/");
      }
    })
  }

  const getExpandedAbbreviations = () => {
    fetch("/api/v1/unabbreviate/expanded-abbreviations-list", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
      },
      body: JSON.stringify({
        "provider_id": props.user.email,
        "assigned_document_id": documentID
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {        
          const values = [];
          if(data && 'expanded_abbreviations' in data){
            for (const expansion of data.expanded_abbreviations){ 
              values.push({
                start: expansion.start_pos,
                end: expansion.end_pos,
                tag: expansion.long_form,
                isNew: expansion.is_new,
                shortForm: expansion.short_form
              });
            }
          }
          setState({value: values, fetched: true})
        })
      } else if(response.status == 300){
        window.localStorage.removeItem('oplus-user')
        window.location.reload(false);
        navigate("/");
      }       
    })
  } 

  const submitExpandedAbbreviations = () => {
    if (state.fetched) {
      const filteredValues = state.value.filter(value => value.tag);
      const expandedAbbreviations = filteredValues.map(value => ({
        start_pos: value.start,
        end_pos: value.end,
        short_form: value.shortForm,
        long_form: value.tag,
        is_new: value.isNew
      }));
      axios.post('/api/v1/unabbreviate/submit-expanded-abbreviations', {
        provider_id: props.user.email,
        assigned_document_id: documentID,
        expanded_abbreviations: expandedAbbreviations
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'oplus-api-token': props.user.token,
      }}).catch(function (error) {
        console.log(error);
      });    
    }
  }

  function submitDocument() {

    let num_sf = 0
    let num_lf = 0
    let new_sf_list = []
    if (state.fetched) {
      const filteredValues = state.value.filter(value => value.tag);
      filteredValues.map((value) => {
        if (value.isNew == true) {
          num_lf = num_lf + 1;
        }
        new_sf_list = [...new_sf_list, value.shortForm]
      });

      fetch("/api/v1/unabbreviate/count-new-abbr", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'oplus-api-token': props.user.token
        },
        body: JSON.stringify({
          "provider_id": props.user.email,
          "expansions": new_sf_list
        })
      }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            num_sf = data
            axios.post('/api/v1/unabbreviate/update-stats-count', {
                  provider_id: props.user.email,
                  num_expansions: state.value.length,
                  num_conflicts: 0,
                  num_claimed_credits: 0,
                  num_new_short_forms: num_sf,
                  num_new_long_forms: num_lf
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    'oplus-api-token': props.user.token,
                  }
                }).catch(function (error) {
              console.log(error);
            });
          })
        } else if (response.status == 300) {
          window.localStorage.removeItem('oplus-user')
          window.location.reload(false);
          navigate("/");
        }
      })
    }

    fetch("/api/v1/unabbreviate/evaluate-all-conflicts", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
      },
      body: JSON.stringify({
        "provider_id": props.user.email,
        "document_id": documentID
      })
    }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            if (data) {
              axios.post('/api/v1/unabbreviate/submit-conflict-document', {
                provider_id: props.user.email,
                assigned_document_id: documentID
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'oplus-api-token': props.user.token,
                }
              }).then(() => {
                    navigate("/conflict_document/" + documentID + "/");
                  }).catch(function (error) {
                    console.log(error);
                  });
            }
            else
            {
              axios.post('/api/v1/unabbreviate/submit-document', {
                provider_id: props.user.email,
                assigned_document_id: documentID
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'oplus-api-token': props.user.token
                }
              }).then(() => {
                navigate("/");
              }).catch(function (error) {
                console.log(error);
              });
            }
        })
      }
    })
  }


  async function submitStats(shortForm, stats) {    
    if(!props.user.tags.includes("test_user")){
      await axios.post('/api/v1/unabbreviate/report-stats', {
        provider_id: props.user.email,
        impressions: stats.impressions.map(longForm => ({short_form: shortForm, long_form: longForm})),
        clicks: stats.clicks.map(longForm => ({short_form: shortForm, long_form: longForm}))
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'oplus-api-token': props.user.token
      }}).catch(function (error) {
        console.log(error);
      });
    }
  }

  const setExpandedAbbreviations = (values) => {    
    setState({...state, value: values})    
  }

  React.useEffect(() => {
    getAbbrMap();
    getExpandedAbbreviations();
    getDocument();
  }, []);
  
  React.useEffect(() => {
    submitExpandedAbbreviations();
  }, [state]);

  const getSugg = () => {
    {
      sugg == 1 ?
          setStrSugg("View Suspected Abbreviations")
          :
          setStrSugg("Hide Suspected Abbreviations")
    }
  }

  const getAgreed = () => {
    {
      agreed == 1 ?
          setStrAgreed("View Confirmed Expansions")
          :
          setStrAgreed("Hide Confirmed Expansions")
    }
  }

  React.useEffect(() => {
    getSugg();
  }, [sugg]);

  React.useEffect(() => {
    getAgreed();
  }, [agreed]);

  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
                          setTimeout(() => {
                              setLoading(false)
                        }, 600)
                      }, []);

  return (    
    <>
      {data != null ? (
        <>
          <Row>
            <Col className="text-start">
                <strong>Instructions</strong>:
                <ol>
                  <li>Click to clarify highlighted abbreviations.</li>
                  <li>Highlight and clarify any abbreviations in the notes not already identified.</li>
                </ol>
            </Col>
            <Col className="text-start" md={3}>
              <table>
                <tr>
                  <td><span style={{backgroundColor: "#fffde3"}}>Yellow</span></td>
                  <td><span>&nbsp;&nbsp;Confirmed Expansions</span></td>
                </tr>
                <tr>
                  <td><span style={{backgroundColor: "#bcf5bc"}}>Green</span></td>
                  <td><span>&nbsp;&nbsp;Suspected Abbreviations</span></td>
                </tr>
                <tr>
                  <td><span style={{backgroundColor: "#84d2ff"}}>Blue</span></td>
                  <td><span>&nbsp;&nbsp;New Abbreviation Expansions</span></td>
                </tr>
              </table>
            </Col>
          </Row>
          <br/>
          <br/>
        <Row>
          <hr className="py"/>
          {loading == true ? (
              <Spinner animation="border" size="md"/>
            ):(null)
          }
          <Col>
            <strong>Admission ID:</strong> {data['admission_id']}
          </Col>
          <Col>
            <strong>Diagnoses:</strong> {data['diagnoses']}
          </Col>
          <Col>
            <strong>Category:</strong> {data['category']}
          </Col>
          <Col>
            <strong>Description:</strong> {data['description']}
          </Col>
        </Row>
        <Row>          
          <Col>
          <hr className="py-1"/>
            <TextAnnotator
                      content={Buffer.from(data['content'], 'base64').toString('utf8')}
                      value={state.value}
                      suggestions={abbr_map}
                      submitStats={submitStats}
                      user={props.user}
                      onChange={setExpandedAbbreviations}
                      sugg={sugg*-1}
                      agreed={agreed*-1}
                      getSpan={span => ({
                          ...span
                      })}                
            /> 
          <hr className="py-1"/>
          </Col>          
        </Row>
        <Row>          
          <Col className="text-start">
            <Link onClick={submitExpandedAbbreviations} to="/">
              <Button variant="secondary" size="md">Home</Button>
            </Link>
            {' '}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="light" size="md" onClick={() => {
              setSugg(sugg * -1);
            }}>
              {str_sugg}
            </Button>
          </Col>
          <Col className="text-end">
          {
            (show_agreed) ? (
                <Button variant="light" size="md" onClick={() => {
                  setAgreed(agreed * -1);
                }}>
                  {str_agreed}
                </Button>
                )
                :
                (null)
          }
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="primary" size="md" background="green" color="green" onClick={() => {
            submitExpandedAbbreviations();
            submitDocument();
            }}>Finalize</Button>
          </Col>          
        </Row>
        </>
      ) : (
        null
      )}
    </>
  );
}