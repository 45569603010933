import React, { useState } from "react";

import { Routes, Route, useNavigate, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './App.scss';
import useLocalStorage from "./Utils"
import DocumentList from "./DocumentList"
import Document from "./Document"
import ConflictDocument from "./ConflictDocument"
import ResultDocument from "./ResultDocument"
import GetMoreDocuments from "./GetMoreDocuments"
import {LoginForm, RequestResetPassword, ResetPassword, ChangePassword, RegisterForm} from "./PasswordManagement";
import axios from 'axios';


export default function Header(props) {
  const max_credits_allowed = 10
  const increment_credits = 0.25
  const [claim_cart, setClaimCart] = React.useState(false);
  const [claim_state, setClaimState] = React.useState(0);
  const [claimable_credits, setClaimableCredits] = React.useState(0);
  const [claimed_credits, setClaimedCredits] = React.useState(0);
  const [credits, setCredits] = React.useState(0);
  const [agreements, setAgreements] = React.useState(0);
  const [num_sf, setNumSf] = React.useState(0);
  const [num_lf, setNumLf] = React.useState(0);

  const getCredits = () => {
      fetch("/api/v1/unabbreviate/get-total-credit-hours", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'oplus-api-token': props.user.token
        },
        body: JSON.stringify({
          "provider_id": props.user.email,
          "increment_interval": increment_credits
        })
      })
      .then(response => {
        if (response.ok) {
          response.json().then(credits => {
            setCredits(credits.toFixed(2))
          })
        }
      })
    }

  const getAllStats = () => {
    fetch("/api/v1/unabbreviate/get-all-stats", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token
      },
      body: JSON.stringify({
        "provider_id": props.user.email
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          setClaimedCredits(data[0].toFixed(2));
          setNumSf(data[1]);
          setNumLf(data[2]);
          setAgreements(data[3].toFixed(2));
        })
      }
    })
  }

  function updateStatsCount(num) {
    console.log(num)
    axios.post('/api/v1/unabbreviate/update-stats-count', {
      provider_id: props.user.email,
      num_expansions: 0,
      num_conflicts: 0,
      num_claimed_credits: num,
      num_new_short_forms: 0,
      num_new_long_forms: 0
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'oplus-api-token': props.user.token,
    }}).catch(function (error) {
      console.log(error);
    });
  }

  React.useEffect(() => {
    getCredits();
    getAllStats();
  }, []);

  React.useEffect(() => {
    if (claimed_credits < max_credits_allowed) {
      let temp = (Math.min(credits, max_credits_allowed) - claimed_credits).toFixed(2);
      setClaimableCredits(temp);
    } else {
      setClaimableCredits(-1);
    }
  }, [credits, claimed_credits]);

  React.useEffect(() => {
    setClaimState(claimable_credits)
  }, [claimable_credits]);


  function MyVerticallyCenteredModal(props) {
    let creds = []
    for (var i = claimable_credits; i > 0; i=i-increment_credits) {  // 0 not included
        creds.push(i);
    }
    return (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="input-group mb align-items-center justify-content-center">
              <br/>
              <br/>
              <strong>CME Credits to Claim = </strong>
              &nbsp;&nbsp;
              <select className="custom-select" variant="primary" id="inputGroupSelect01" value={claim_state} onChange={e => setClaimState(e.target.value)}>
                {creds.map(item => <option value={item}>{item}</option>)}
              </select>
              <br/>
              <br/>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={props.onHide}>Close</Button>&nbsp;&nbsp;&nbsp;
              <Button variant="primary" onClick={props.onSubmit}>Submit</Button>
          </Modal.Footer>
        </Modal>
      );
    }

  return (
    <Navbar variant='dark'>
      <MyVerticallyCenteredModal
            show={claim_cart}
            onHide={() => {
              setClaimCart(false);
              setClaimState(claimable_credits)
            }}
            onSubmit={() => {
              updateStatsCount(Number(claim_state))
              setClaimCart(false);
              getAllStats();
              window.open('https://cpd.education.bcm.edu/content/abbreviations-course-enduring-activity#group-tabs-node-course-default3', '_blank');
              window.location.reload();
            }}
      />
      <Container fluid>
        <Navbar.Brand href="/"><strong>UnAbbreviate!</strong> <br/> <small>[beta]</small> </Navbar.Brand>
        <Nav style={{color: 'white', borderBottom: '2px solid green', borderTop: '2px solid green', paddingTop:"0.5%", paddingBottom:"0.5%", display:'inline-block'}}>
          <strong>Credit Hours:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Completed&nbsp;=&nbsp;<strong>{credits}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {(claimable_credits == -1)
              ? (<i><small>All possible <strong>{max_credits_allowed}</strong> Credits Claimed</small></i>)
              :
                  (claimable_credits > 0) ? (
                      <>
                        Claimed&nbsp;=&nbsp;<strong>{claimed_credits}</strong>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;<Button variant="light" size="sm" onClick={() => {
                          setClaimCart(true);
                        }}>
                        Claim upto <strong>{claimable_credits}</strong> new Credits</Button>
                      </>
                  )
                  :
                  (<>
                    Claimed&nbsp;=&nbsp;<strong>{claimed_credits}</strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i><small>No Credits to Claim</small></i>
                  </>)

          }

        </Nav>
        <Nav style={{color: 'white', borderBottom: '2px solid green', borderTop: '2px solid green', paddingTop:"0.5%", paddingBottom:"0.5%"}}>
         Agreement Score&nbsp;=&nbsp;<strong>{agreements} %</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unique Abbr&nbsp;=&nbsp;<strong>{num_sf}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unique Expansions&nbsp;=&nbsp;<strong>{num_lf}</strong>
        </Nav>
        <Nav>
          <NavDropdown title={props.user.email} id="nav-dropdown" align="end">
            <NavDropdown.Item href="/get-more-documents">Request more notes</NavDropdown.Item>
            <NavDropdown.Divider/>
            <NavDropdown.Item href="/change-password">Change password</NavDropdown.Item>
            <NavDropdown.Item onClick={props.onLogout}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
}