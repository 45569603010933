import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


export default function Disclaimer(props) {
      return (
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Disclaimer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
            <h6><u><b>NEEDS STATEMENT</b></u></h6>
            The activity uses active learning on actual medical texts (anonymized) to increase provider awareness of abbreviation use. By asking the provider to try to discern the correct meaning of the abbreviation and then providing statistical information on provider agreement further illustrates the problem with the inappropriate use of abbreviations or use of non-standard medical abbreviations.
            </p>
            <p>
            <h6><u><b>TARGET AUDIENCE</b></u></h6>
            This program is intended for Common Spirit Health faculty and Barrow Neurological Institute, any other healthcare institution.
            </p>
            <p>
            <h6><u><b>EDUCATIONAL METHODS</b></u></h6>
            Enduring material (Simulation)
            </p>
            <p>
            <h6><u><b>ACTIVITY EVALUATION</b></u></h6>
            An evaluation by questionnaire will address program content, presentation, and possible bias.
            </p>
            <p>
            <h6><u><b>EDUCATIONAL OBJECTIVES</b></u></h6>
            At the conclusion of the activity, participants should be able to:
                <ul>
                    <li>Identify and address instances of inappropriate or ambiguous usage of medical abbreviations or acronyms.</li>
                    <li>Discuss the importance of recognizing medical context when interpreting the meaning of abbreviations or acronyms.</li>
                    <li>Recognize the presence of inter-observer variability when interpreting medical abbreviations/acronyms, which can potentially lead to miscommunication.</li>
                </ul>
            </p>
            <p>
            <h6><u><b>ACCREDITATION/CREDIT DESIGNATION</b></u></h6>
            This activity has been planned and implemented in accordance with the accreditation requirements and policies of the Accreditation Council for Continuing Medical Education (ACCME) through the joint providership of Baylor College of Medicine and Common Spirit. Baylor College of Medicine is accredited by the ACCME to provide continuing medical education for physicians.<br/><br/>
            Baylor College of Medicine designates this enduring activity for a maximum of 10 <i>AMA PRA Category 1 Credits</i>™. <br/>
            Physicians should claim only the credit commensurate with the extent of their participation in the activity.
            </p>
            <p>
            <h6><u><b>DISCLOSURE POLICY</b></u></h6>
            Baylor College of Medicine (BCM) is accredited by the Accreditation Council for Continuing Medical Education (ACCME) to provide continuing medical education (CME) for physicians. BCM is committed to sponsoring CME activities that are scientifically based, accurate, current, and objectively presented.<br/><br/>
            In accordance with the ACCME Standards for Commercial Support, BCM has implemented a mechanism requiring everyone in a position to control the content of an educational activity (i.e., directors, planning committee members, faculty) to disclose any relevant financial relationships with commercial interests (drug/device companies) and manage/resolve any conflicts of interest prior to the activity.  Individuals must disclose to participants the existence or non-existence of financial relationships: 1) at the time of the activity or within 12 months prior; and 2) of their spouses/partners.<br/><br/>
            In addition, BCM has requested activity faculty/presenters to disclose to participants any unlabeled use or investigational use of pharmaceutical/device products; to use scientific or generic names (not trade names) in referring to products; and, if necessary to use a trade name, to use the names of similar products or those within a class. Faculty/presenters have also been requested to adhere to the ACCME’s validation of clinical content statements.<br/><br/>
            BCM does not view the existence of financial relationships with commercial interests as implying bias or decreasing the value of a presentation. It is up to participants to determine whether the relationships influence the activity faculty with regard to exposition or conclusions.  If at any time during this activity you feel that there has been commercial/promotional bias, notify the Activity Director or Activity Coordinator.  Please answer the questions about balance and objectivity in the activity evaluation candidly.<br/><br/>
            All of the relevant financial relationships listed for these individuals have been mitigated.
            </p>
            <p>
            <h6><u><b>DISCLOSURES</b></u></h6>
                <table style={{"border": "1px solid black", "text-align": "center", "width": "100%", "font-size": "14px", "font-family": "Calibri, sans-serif"}}>
                    <tr style={{"background-color": "#f6f6f6"}}>
                        <th style={{"border": "1px solid black", "text-align": "center", "width": "40%"}}>Name</th>
                        <th colspan={"3"} style={{"border": "1px solid black", "text-align": "center", "width": "40%"}}>Role(s)</th>
                        <th style={{"border": "1px solid black", "text-align": "center", "width": "20%"}}>Relationship(s)</th>
                    </tr>
                    <tr style={{"background-color": "#f6f6f6"}}>
                        <td></td>
                        <td style={{"border": "1px solid black", "text-align": "center"}}><b>Planning Member</b></td>
                        <td style={{"border": "1px solid black", "text-align": "center"}}><b>Contributor</b></td>
                        <td style={{"border": "1px solid black", "text-align": "center"}}><b>Peer Reviewer</b></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style={{"border": "1px solid black", "text-align": "left", "padding-left": "10px"}}><b>Igor Barani, MD – Activity Director</b><br/>
                            Professor and Chair, Oncology<br/>
                            Barrow Neurological Institute</td>
                        <td style={{"border": "1px solid black", "text-align": "center"}}>●</td>
                        <td style={{"border": "1px solid black", "text-align": "center"}}>●</td>
                        <td style={{"border": "1px solid black", "text-align": "center"}}></td>
                        <td style={{"border": "1px solid black", "text-align": "left", "padding-left": "10px"}}>Nothing to disclose</td>
                    </tr>
                    <tr>
                        <td style={{"border": "1px solid black", "text-align": "left", "padding-left": "10px"}}><b>Eva Duran, APRN, FNP-C</b><br/>
                            Nurse Practitioner<br/>
                            Barrow Neurological Institute</td>
                        <td style={{"border": "1px solid black", "text-align": "center"}}>●</td>
                        <td style={{"border": "1px solid black", "text-align": "center"}}></td>
                        <td style={{"border": "1px solid black", "text-align": "center"}}>●</td>
                        <td style={{"border": "1px solid black", "text-align": "left", "padding-left": "10px"}}>Nothing to disclose</td>
                    </tr>
                </table><br/>
                In June 2023, this continuing medical education online enduring activity was reviewed by: Eva Duran, APRN, FNP-C. To ensure the continued scientific relevance of this enduring material, its content will be reviewed again in June 2024.<br/><br/>
                ** All Faculty are with Barrow Neurological Institute, unless specified otherwise.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      );
}