import React from 'react'
import sortBy from 'lodash.sortby'
import take from 'lodash.take'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import { useRef, useEffect } from "react";

const selectionIsEmpty = (selection) => {
    if (!selection.anchorNode){
        return true
    } 
    let position = selection.anchorNode.compareDocumentPosition(selection.focusNode)
    return position === 0 && selection.focusOffset === selection.anchorOffset
  }
  
const selectionIsBackwards = (selection) => {
    if (selectionIsEmpty(selection)) return false
  
    let position = selection.anchorNode.compareDocumentPosition(selection.focusNode)
  
    let backward = false
    if (
      (!position && selection.anchorOffset > selection.focusOffset) ||
      position === Node.DOCUMENT_POSITION_PRECEDING
    ) {
      backward = true
    }
  
    return backward
}

const splitWithOffsets = (text, offsets, suggestion_offsets) => {
    let lastEnd = 0
    const splits = []
    for (let offset of sortBy(offsets, o => o.start)) {
      const {start, end} = offset
      if (lastEnd < start) {
          let suggestion_lastEnd = lastEnd
          let current_start = lastEnd
          let current_end = start
          for (let suggestion_offset of sortBy(suggestion_offsets, s => s.suggestion_start)) {
              const {suggestion_start, suggestion_end, suggestion_type, suggestion_lf} = suggestion_offset
              if (suggestion_start < current_start) {
                  continue;
              }
              if (suggestion_end > current_end){
                  break;
              }
              if (suggestion_lastEnd < suggestion_start) {
                splits.push({
                  start: suggestion_lastEnd,
                  end: suggestion_start,
                  content: text.slice(suggestion_lastEnd, suggestion_start),
                })
              }

              let content_now = text.slice(suggestion_start, suggestion_end);
              if (suggestion_type == "expand_in_place") {
                  content_now = suggestion_lf
              }

              splits.push({
                  start: suggestion_start,
                  end: suggestion_end,
                  suggestion_mark: true,
                  suggestion_type: suggestion_type,
                  suggestion_lf: suggestion_lf,
                  content: content_now,
              })
              suggestion_lastEnd = suggestion_end
            }
            if (suggestion_lastEnd < current_end) {
              splits.push({
                start: suggestion_lastEnd,
                end: current_end,
                content: text.slice(suggestion_lastEnd, current_end),
              })
            }

      }
      splits.push({
        ...offset,
        mark: true,
        content: text.slice(start, end),
      })
      lastEnd = end
    }
    if (lastEnd < text.length) {
          let suggestion_lastEnd = lastEnd
          let current_start = lastEnd
          let current_end = text.length
          for (let suggestion_offset of sortBy(suggestion_offsets, s => s.suggestion_start)) {
              const {suggestion_start, suggestion_end, suggestion_type, suggestion_lf} = suggestion_offset
              if (suggestion_start < current_start) {
                  continue;
              }
              if (suggestion_end > current_end){
                  break;
              }
              if (suggestion_lastEnd < suggestion_start) {
                splits.push({
                  start: suggestion_lastEnd,
                  end: suggestion_start,
                  content: text.slice(suggestion_lastEnd, suggestion_start),
                })
              }

              let content_now = text.slice(suggestion_start, suggestion_end);
              if (suggestion_type == "expand_in_place") {
                  content_now = suggestion_lf
              }

              splits.push({
                  start: suggestion_start,
                  end: suggestion_end,
                  suggestion_mark: true,
                  suggestion_type: suggestion_type,
                  suggestion_lf: suggestion_lf,
                  content: content_now,
              })
              suggestion_lastEnd = suggestion_end
            }
            if (suggestion_lastEnd < current_end) {
              splits.push({
                start: suggestion_lastEnd,
                end: current_end,
                content: text.slice(suggestion_lastEnd, current_end),
              })
            }
    }
    return splits
}

function Mark(props) {

    const [longForms, setLongForms] = React.useState([]);
    const [filterValue, setFilterValue] = React.useState('');
    const [stats, setStats] = React.useState({ clicks: [], impressions: []});
    
    const getLongForms = () => {
      if(longForms.length <= 0){        
        if(!props.tag){
          fetch("/api/v1/unabbreviate/long-forms-for-token", {
              method: "POST",
              headers: {
              'Content-Type': 'application/json',
              'oplus-api-token': props.user.token
              },
              body: JSON.stringify({                  
                  "token": props.shortForm
              })
          })
          .then(response => {
              if (response.ok) {
                response.json().then(data => {
                  if('long_forms' in data){
                    setLongForms(data.long_forms)       
                  }           
                })
              } else if(response.status == 300){
                window.localStorage.removeItem('oplus-user')
                window.location.reload(false);
              }               
          })
        }
      }
    }

    React.useEffect(() => {
      getLongForms();
    }, []); 

    React.useEffect(() => {
      incrementImpressions();
    }, [filterValue, longForms]); 

    function onTagSelected(event) {
        props.onTagChange({ start: props.start, end: props.end, tag: event.target.text, shortForm: props.content, isNew: false }) 
        props.submitStats(props.content, {clicks: [event.target.text], impressions: stats.impressions})
    }

    function onNewTag() {
      props.onTagChange({ start: props.start, end: props.end, tag: filterValue, shortForm: props.content, isNew: true }) 
    }

    function incrementImpressions(){  
      const longFormsImpressed = topN(3).map(longForm => longForm.long_form);      
      setStats({clicks: stats.clicks, impressions: [...new Set(stats.impressions.concat(longFormsImpressed))]});      
    }

    const topN = (n) => {
      const allLongForms = sortBy(longForms, longForm => -longForm.score);
      const filteredLongForms = take(allLongForms.filter(
        (longForm) =>
        longForm.long_form.toLowerCase().startsWith(filterValue),
      ), n);      
      return filteredLongForms
    }

    /**
    * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
      useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            // "You clicked outside of me!"
              setCloseDropDown(true);
              props.onClick({start: props.start, end: props.end})
          }
        }
        // Bind the event listener
        document.addEventListener("pointerdown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("pointerdown", handleClickOutside);
        };
      }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    const [closeDropdown, setCloseDropDown] = React.useState(false);

    return (
        <>
            {(!props.tag && !closeDropdown) ?
            (<>
                <Dropdown autoClose="inside" className="d-inline-block" ref={wrapperRef}>
                    <Dropdown.Menu show className="show">
                      <Form.Control
                          autoFocus
                          className="mx-3 my-2 w-auto"
                          placeholder="Type to filter..."
                          onChange={(e) => setFilterValue(e.target.value)}
                          value={filterValue}
                          onKeyPress={event => {
                                          if (event.key === "Enter") {
                                            onNewTag()
                                          }
                                        }}
                        />
                        {topN(3).map((longForm, i) =>
                            <Dropdown.Item key={i} onClick={onTagSelected} style={{cursor: 'pointer'}}>{longForm.long_form}</Dropdown.Item>
                        )}
                        <Dropdown.Divider/>
                        <Dropdown.Item onClick={onNewTag} style={{cursor: 'pointer'}}>+ New</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>) : (null)}
            <mark
              style={{backgroundColor: props.color || '#84d2ff', padding: '0 0px', cursor: 'pointer'}}
              data-start={props.start}
              data-end={props.end}
              onPointerDown={() => props.onClick({start: props.start, end: props.end})}
            >
              {props.content}
              {props.tag && (
                <span style={{fontSize: '0.7em', fontWeight: 500, marginLeft: 6}}>[{props.tag}]</span>
              )}
            </mark>
        </>
    )
}

const Split = props => {
  if (props.mark) {return <Mark {...props} />}
  else if (props.suggestion_mark && props.suggestion_type == "expand_in_place") {
      if (props.agreed == 1) {
          return (
              <span
                  style={{backgroundColor: '#fffde3', padding: '0 0px', cursor: 'pointer'}}
              >
              {props.content}
            </span>
          )
      } else {
          return (
              <span
                  style={{backgroundColor: '#FFFFFF', padding: '0 0px', cursor: 'pointer'}}
              >
              {props.content}
            </span>
          )
      }
  }
  else if (props.suggestion_mark && props.sugg == 1) {
      if (props.suggestion_type == "heuristic" || props.suggestion_type == "previous_submission") {
          return (
              <>
                <mark
                  data-start={props.start}
                  data-end={props.end}
                  style={{backgroundColor: '#bcf5bc', padding: '0 0px', cursor: 'pointer'}}
                  onClick={() =>  props.onHandleClickOnSugg({start: props.start, end: props.end})}
                  onPointerDown={() => props.onClick({start: props.start, end: props.end})}
                >
                  {props.content}
                </mark>
              </>
          )
        } else {  // default if no suggestion_type
              return (
                  <>
                    <mark
                      data-start={props.start}
                      data-end={props.end}
                      style={{backgroundColor: '#1ec71e', padding: '0 0px', cursor: 'pointer'}}
                      onPointerDown={() => props.onClick({start: props.start, end: props.end})}
                    >
                      {props.content}
                    </mark>
                  </>
              )
      }
  }
  else {
        return (
            <span
                style={{cursor: 'pointer'}}
                data-start={props.start}
                data-end={props.end}
                onPointerDown={() => props.onClick({start: props.start, end: props.end})}
            >
          {props.content}
        </span>
        )
    }
}


const TextAnnotator = (props) => {
  const getSpan = (span) => {
    if (props.getSpan) return props.getSpan(span)
    return {start: span.start, end: span.end}
  }

  const handlePointerUp = (event) => {
    if (!props.onChange) {
        window.getSelection().empty()
        return
    }

    const numOpenItems = props.value.map((item) => item.tag ? 0 : 1).reduce((a, b) => a + b, 0)
    if (numOpenItems > 0) {
        window.getSelection().empty()
        return
    }

    const selection = window.getSelection()

    if (selectionIsEmpty(selection)) {
        window.getSelection().empty()
        return
    }

    let start =
      parseInt(selection.anchorNode.parentElement.getAttribute('data-start'), 10) +
      selection.anchorOffset

    let end =
      parseInt(selection.focusNode.parentElement.getAttribute('data-start'), 10) +
      selection.focusOffset

    if (isNaN(start) || isNaN(end)) {
        window.getSelection().empty()
        return
    }

    if (selectionIsBackwards(selection)) {
      ;[start, end] = [end, start]
    }

    // don't allow only whitespaces
    if (!content.slice(start, end).replace(/\s/g, '').length) {
        window.getSelection().empty()
        return
    }

    // don't allow selection which already has >0 expansions in it
    const values = props.value
    let middle_exp = false
    values.map (
        (item) =>  {
            if ((start <= parseInt(item.start) && parseInt(item.start) < end)
                   ||
                   (start < parseInt(item.end) && parseInt(item.end) <= end))
                {
                    middle_exp = true
                }
        }
    )

    const suggestions1 = props.suggestions
    suggestions1.map (
        (item) =>  {
            if (
                (item.suggestion_type == "expand_in_place")
                && (
                       (start <= parseInt(item.suggestion_start) && parseInt(item.suggestion_start) < end)
                       ||
                       (start < parseInt(item.suggestion_end) && parseInt(item.suggestion_end) <= end)
                    )
                )
                {
                    middle_exp = true
                }
        }
    )

    if (middle_exp == false) {
        props.onChange([...props.value, getSpan({start, end, shortForm: content.slice(start, end)})])
    }

    window.getSelection().empty()
  }

  const handleSplitClick = ({start, end}) => {
    const splitIndex = props.value.findIndex(s => s.start === start && s.end === end)
    if (splitIndex >= 0) {
      props.onChange([...props.value.slice(0, splitIndex), ...props.value.slice(splitIndex + 1)])
    }
  }

  const handleTagChange = (newValue) => {
    const pos = props.value.findIndex(s => s.start === newValue.start && s.end === newValue.end)
    props.onChange([...props.value.slice(0, pos), newValue, ...props.value.slice(pos + 1)])
  }

  const HandleClickOnSugg = ({start, end}) => {
      // don't allow selection which already has >0 expansions in it
        const values = props.value
        let middle_exp = false
        values.map (
            (item) =>  {
                if ((start <= parseInt(item.start) && parseInt(item.start) < end)
                       ||
                       (start < parseInt(item.end) && parseInt(item.end) <= end))
                    {
                        middle_exp = true
                    }
            }
        )
      if (middle_exp == false) {
          props.onChange([...props.value, getSpan({start, end, shortForm: content.slice(start, end)})])
      }
  }

  const {content, value, style, suggestions, sugg, agreed} = props
  const splits = splitWithOffsets(content, value, suggestions)

  return (
    <div onPointerUp={handlePointerUp} style={{cursor: 'pointer'}}>
      {splits.map((split) => (
        <Split key={`${split.start}-${split.end}`} {...split} onHandleClickOnSugg={HandleClickOnSugg} onClick={handleSplitClick} onTagChange={handleTagChange} user={props.user} submitStats={props.submitStats} suggestions={suggestions} sugg={sugg} agreed={agreed}/>
      ))}      
    </div>
  )
}

export default TextAnnotator