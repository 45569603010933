import React from "react";
import axios from 'axios';

import { Link, useSearchParams, useNavigate} from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import FormFloating from 'react-bootstrap/FormFloating';
import FormGroup from 'react-bootstrap/FormGroup';
import Disclaimer from './disclaimer'


function validateEmail(email) {
    const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return email.trim().length === 0 || email.match(validEmail);
}

export function LoginForm(props) {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [auth_fail, setAuthFail] = React.useState(false);
  
    const login = (event) => {
      event.preventDefault();
      setLoading(true)
      setAuthFail(false);
      fetch("/api/v1/login", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'         
        },
        body: JSON.stringify({
          "username": email.toLowerCase(),
          "password": password
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Authentication Failed');
        }
        response.json().then(data => {
          setLoading(false)
          props.onSuccess(email.toLowerCase(), data['oplus-api-token'], data['tags']);
        })
      }).finally(() => {
        setLoading(false)
        setEmail('');
        setPassword('');
        setAuthFail(true);
      })
    }

    const [loading, setLoading] = React.useState(false);
  
    return ( 
      <Card.Body className="p-4 p-sm-5">
        <Card.Title className='text-center mb-4 fs-5'>Sign In</Card.Title>
          {loading == true ? (
              <Card.Subtitle className='text-center mb-3 text-danger fs-6'>
                <Spinner animation="border" size="sm"/>
              </Card.Subtitle>
            ):(null)
          }
          {
              auth_fail ? (
                  <Card.Subtitle className='text-center mb-3 text-danger fs-6'>Authentication Failed, Please Try Again</Card.Subtitle>
              )
              :
              (null)
          }
          <Form>
          <FormGroup className="mb-4">
            <FormFloating className="mb-3">
              <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} value={email}/>
              <Form.Label>
                Email address
              </Form.Label>
            </FormFloating>
          </FormGroup>
  
          <FormGroup className="mb-4">
            <FormFloating className="mb-3">
              <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password}/>
              <Form.Label>
                Password
              </Form.Label>                    
            </FormFloating>
          </FormGroup>
          <FormGroup className="mb-4 d-grid gap-2">
            <Button variant="primary" type="submit" onClick={login} size="md">Sign in</Button>
          </FormGroup>
          <FormGroup className="mb-4">
            <Row>
                <Col>
                    <Link to="/request-reset-password">Forgot password?</Link>
                </Col>  
                <Col>
                    Not a member? <Link to="/register">Register</Link>
                </Col>              
            </Row>
          </FormGroup>            
        </Form>
      </Card.Body>       
    );
  }
  
  export function ChangePassword(props) {
  
    const [password, setPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmedPassword, setConfirmedPassword] = React.useState('');
    const [passwordChangeState, setPasswordChangeState] = React.useState(-1);  
  
    function changePassword() {
      if(passwordChangeState < 0 && newPassword == confirmedPassword && newPassword.trim().length !== 0 && newPassword !== password){
        setPasswordChangeState(0)
        axios.post('/api/v1/change_password', {
          email: props.user.email,
          password: password,
          new_password: newPassword
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'oplus-api-token': props.user.token
        }}).then(res => setPasswordChangeState(1)).catch(err => setPasswordChangeState(-2));    
      }
    }
  
    return (
      <>
      {(passwordChangeState > 0) ? (
        <>
        <div className='text-primary mb-5 fw-light fs-5'>Your password has been successfully changed!</div>
        <Button variant="secondary" href="/">
          Go back
        </Button>
        </>
      ) : (
      <Form>
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formNewPassword">
          <Form.Label>New Password</Form.Label>
          <Form.Control type="password" placeholder="New Password" onChange={(e) => setNewPassword(e.target.value)} value={newPassword}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formConfirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control type="password" placeholder="Confirm Password" onChange={(e) => setConfirmedPassword(e.target.value)} value={confirmedPassword}/>
        </Form.Group>
        <FormGroup className="mb-4">
          {newPassword != confirmedPassword? <div className="text-danger">*Please make sure your passwords match</div> : ''}
          {(passwordChangeState === -2)? <div className="text-danger">*Could not change password. Please check your current password and try again.</div> : ''}
        </FormGroup>
        <Button variant="secondary" href="/">
          Cancel
        </Button>{' '}
        <Button variant="primary" onClick={changePassword}>
          Change
        </Button>
      </Form>
      )}
      </>
    )
  }
  
  export function ResetPassword() {
  
    const [searchParams, setSearchParams] = useSearchParams();
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmedPassword, setConfirmedPassword] = React.useState('');
    const [requestState, setRequestState] = React.useState(-1);
    
    function changePassword() {
      setRequestState(0)
      if(requestState < 0 && newPassword == confirmedPassword && newPassword.trim().length !== 0){
        axios.post('/api/v1/change_password', {
          email: searchParams.get("email"),
          password: searchParams.get("oplus-api-token"),
          new_password: newPassword
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'oplus-api-token': searchParams.get("oplus-api-token")
        }}).then(res => setRequestState(1)).catch(err => setRequestState(2));    
      }
    }
  
    return (
      <Card.Body className="p-4 p-sm-5">
        <Card.Title className='text-center mb-5 fw-light fs-5'>Set your new password</Card.Title>
        {(requestState > 0) ? (
          <>
          {(requestState == 1)? <Card.Text className='text-center text-primary mb-5 fw-light fs-5'>Your password has been successfully reset. Please use new password to <Link to="/">login</Link>.</Card.Text> : ''}
          {(requestState == 2)? <Card.Text className='text-center text-danger mb-5 fw-light fs-5'>Could not change your password. Provided token has expired.</Card.Text> : ''}
        </>
        ):(
        <Form>
          <Form.Group className="mb-3" controlId="formNewPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control type="password" placeholder="New Password" onChange={(e) => setNewPassword(e.target.value)} value={newPassword}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formConfirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control type="password" placeholder="Confirm Password" onChange={(e) => setConfirmedPassword(e.target.value)} value={confirmedPassword}/>
          </Form.Group>
          <FormGroup className="mb-4">
            {newPassword != confirmedPassword? <div className="text-danger">*Please make sure your passwords match</div> : ''}          
          </FormGroup>
          <Button variant="primary" onClick={changePassword}>
            Change my password
          </Button>
        </Form>
        )}
      </Card.Body>
    )
  }
  
  export function RequestResetPassword() {
  
    const [email, setEmail] = React.useState('');
    const [resetEmailSent, setResetEmailSent] = React.useState(-1);
    const [isEmailValid, setEmailValid] = React.useState(true);
    const recaptchaRef = React.useRef(null)
    const navigate = useNavigate();
  
    React.useEffect(() => {
        setEmailValid(validateEmail(email));
    }, [email]);
    
    const resetPassword = async () => { 
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        if(resetEmailSent < 0 && email.trim().length !== 0 && isEmailValid && captchaToken.trim().length !== 0){        
            setResetEmailSent(0); // In progress
            await axios.post('/api/v1/send-password-reset-link', {
                email: email,
                captcha_token: captchaToken,
                application_id: 'unabbreviate'
            },
            {
                headers: {
                    'Content-Type': 'application/json'          
            }}).then(res => setResetEmailSent(1)).catch(err => setResetEmailSent(2));
        }
    }
  
    return (
      <Card.Body className="p-4 p-sm-5">
        <Card.Title className='text-center mb-5 fw-light fs-5'>Forgot your password?</Card.Title>
        {(resetEmailSent > 0) ? (
            <>
              {(resetEmailSent == 1)? <Card.Text className='text-center text-primary mb-5 fw-light fs-5'>We have sent you a password reset link to your e-mail. Please check your inbox.</Card.Text> : ''}
              {(resetEmailSent == 2)? <Card.Text className='text-center text-danger mb-5 fw-light fs-5'>We were not able to send reset link to your e-mail. Please contact your administrator for more information.</Card.Text> : ''}
            <FormGroup className="mb-4 d-grid gap-2">
                <Button variant="secondary"onClick={() => {
                      navigate("/");
                    }}>Login Page</Button>
            </FormGroup>
            </>
        ) : (  
          <Form onSubmit={() => { recaptchaRef.current.execute(); }}>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Please enter your email address below</Form.Label>
              <Form.Control type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email}/>
            </Form.Group>   
            <FormGroup className="mb-4" controlId="emailInvalid">          
              {!isEmailValid? <div className="text-warning">Please provide valid email address.</div> : ''}
            </FormGroup>
            <Form.Group className="mb-3" controlId="formCaptcha">
                <ReCAPTCHA sitekey={'6Lcu37AmAAAAANfRZFUiQE684IBOlUkJr-NhvF2i'} ref={recaptchaRef} size="invisible"/>
            </Form.Group> 
            <Button variant="primary" onClick={resetPassword}>
              Reset my password
            </Button>
              <br/>
              <br/>
            <Button variant="secondary" onClick={() => {
                navigate("/");
              }}>Back
            </Button>
          </Form>
        )}
      </Card.Body>
    )
  }

  export function RegisterForm() {
  
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmedPassword, setConfirmedPassword] = React.useState('');
    const [registerRequestStatus, setRegisterRequestStatus] = React.useState(-1);
    const [isEmailValid, setEmailValid] = React.useState(true);
    const recaptchaRef = React.useRef(null)
    const navigate = useNavigate();
    const [modalShow, setModalShow] = React.useState(true);
  
    React.useEffect(() => {
        setEmailValid(validateEmail(email));
    }, [email]);

    
    const register = async () => { 
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        if(registerRequestStatus < 0 && email.trim().length !== 0 && captchaToken.trim().length !== 0 && isEmailValid && password == confirmedPassword){
            setRegisterRequestStatus(0); // In progress
            await axios.post('/api/v1/register', {
                username: email,
                password: password,
                captcha_token: captchaToken,
                application_id: 'unabbreviate'
            },
            {
            headers: {
                'Content-Type': 'application/json'          
            }}).then(res => setRegisterRequestStatus(1)).catch(err => {if (err) {
                    setRegisterRequestStatus(2)
            } else {
                    setRegisterRequestStatus(3)
                }});
        }
      }    
  
    return (
      <Card.Body className="p-4 p-sm-5">
        <Card.Title className='text-center mb-5 fw-light fs-5'>Welcome to Unabbreviate!</Card.Title>        
        {(registerRequestStatus > 0) ? (
            <>
              {(registerRequestStatus == 1)? <Card.Text className='text-center text-primary mb-5 fw-light fs-5'>Thank you for registering! Your request has been submitted to UnAbbreviate team. We will review your request and notify you via email when your account is ready.</Card.Text> : ''}
                {(registerRequestStatus == 2)? <Card.Text className='text-center text-danger mb-5 fw-light fs-5'>Email address is already registered. Please try logging in or using the forgot password option. <br/><br/> <Button variant="secondary" onClick={() => {
                      navigate("/");
                    }}>Back</Button></Card.Text> : ''}
              {(registerRequestStatus == 3)? <Card.Text className='text-center text-danger mb-5 fw-light fs-5'>We were not able to submit your request to UnAbbreviate team. Please try again later. <br/><br/> <Button variant="secondary" onClick={() => {
                      navigate("/");
                    }}>Back</Button></Card.Text> : ''}
            </>
        ) : (  
        <>
          <Disclaimer
              show={modalShow}
              onHide={() => setModalShow(false)}
          />
          <Card.Text>To get started, please fill in the form below</Card.Text>
          <Form>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email}/>
            </Form.Group>            
            <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formConfirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" placeholder="Confirm Password" onChange={(e) => setConfirmedPassword(e.target.value)} value={confirmedPassword}/>
            </Form.Group> 
            <FormGroup className="mb-4">          
              {!isEmailValid? <div className="text-warning">Please provide valid email address.</div> : ''}
              {password != confirmedPassword? <div className="text-warning">Please make sure your passwords match</div> : ''}
            </FormGroup>
            <Form.Group className="mb-3" controlId="formCaptcha">
                <ReCAPTCHA sitekey={'6Lcu37AmAAAAANfRZFUiQE684IBOlUkJr-NhvF2i'} ref={recaptchaRef} size="invisible"/>
            </Form.Group>
            <FormGroup className="mb-4 d-grid gap-2">
                <Button variant="primary" onClick={register}>Register</Button>
            </FormGroup>
            <FormGroup className="mb-4 d-grid gap-2">
                <Button variant="secondary"onClick={() => {
                      navigate("/");
                    }}>Back</Button>
            </FormGroup>
          </Form>
        </>
        )}
      </Card.Body>
    )
  }

