import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import Pagination from './Pagination';

const PageSize = 10;

export default function DocumentList(props) {

    const [data, setData] = React.useState({documents: [], n_documents: 0});
    const [currentPage, setCurrentPage] = React.useState(1);
    const navigate = useNavigate();

    React.useEffect(() => {
      getData();
    }, [currentPage]);

    const getData = () => {
      fetch("/api/v1/unabbreviate/assigned-documents-list", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'oplus-api-token': props.user.token
        },
        body: JSON.stringify({
          "provider_id": props.user.email,
          "summary_length": 100,
          "page": currentPage,
          "limit": PageSize
        })
      })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            setData(data)
          })
        } else if(response.status == 300){
          window.localStorage.removeItem('oplus-user')
          window.location.reload(false);
          navigate("/");
        }
      })
    }

    const tableItems = data['documents'].map((document) =>
        document.status == "new"
            ?
        (<tr key={document.document_id}>
          <th scope="row"><Link to={`document/${document.document_id}`}>{document.document_id}</Link></th>
          <td>{document.category}</td>
          <td>{document.summary}</td>
          <td>{document.status}</td>
        </tr>)
        :
            document.status == "submitted"
                ?
            (<tr key={document.document_id}>
              <th scope="row"><Link to={`result_document/${document.document_id}`}>{document.document_id}</Link></th>
              <td>{document.category}</td>
              <td>{document.summary}</td>
              <td>{document.status}</td>
            </tr>)
                :
                (<tr key={document.document_id}>
                  <th scope="row"><Link to={`conflict_document/${document.document_id}`}>{document.document_id}</Link></th>
                  <td>{document.category}</td>
                  <td>{document.summary}</td>
                  <td>{document.status}</td>
                </tr>)

    );
  
    return ( 
      <>
      <br/>
      <br/>
      <div className="table-responsive">
      <table className="table table-striped table-hover table-borderless">
        <tbody>
          {tableItems}
        </tbody>
      </table>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={data['n_documents']}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
      </> 
    );
  }